import React from 'react'
export const AppListHeaderSmall = () => {
  return (
    <div className="flex items-center ml-6">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5H5V0H0V5ZM7.5 20H12.5V15H7.5V20ZM0 20H5V15H0V20ZM0 12.5H5V7.5H0V12.5ZM7.5 12.5H12.5V7.5H7.5V12.5ZM15 0V5H20V0H15ZM7.5 5H12.5V0H7.5V5ZM15 12.5H20V7.5H15V12.5ZM15 20H20V15H15V20Z"
          fill="#2460D1"
        />
      </svg>
      <span
        className="ml-3"
        style={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '156.5%',
          letterSpacing: '0.03em',
          color: '#333333',
        }}
      >
        アプリ一覧
      </span>
    </div>
  )
}
