import { fullUrl } from 'scripts/fullUrl'
import {
  PatchEnduserAppIconSizeInThePortalResponse,
  PatchEnduserAppSortResponse,
} from 'types/ApiResponse'
import {
  patchEnduserAppIconSizeInThePortalRequest,
  patchEnduserAppSortRequest,
} from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const patchEnduserAppIconSizeInThePortal = async (
  params: patchEnduserAppIconSizeInThePortalRequest & {
    enduser_self_id: string
  },
) => {
  const { enduser_self_id, ...remainingParams } = params
  const res =
    await axiosClient.patch<PatchEnduserAppIconSizeInThePortalResponse>(
      `${fullUrl(
        'api',
      )}/endusers/${enduser_self_id}/app_icon_size_in_the_portal`,
      remainingParams,
    )
  return res
}
