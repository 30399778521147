import React from 'react'

export const NoApp = () => {
  return (
    <div
      style={{
        minHeight: '70vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '100px',
        padding: '0 10px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '156.5%',
        letterSpacing: '0.03em',
        color: '#000000',
      }}
    >
      アカウントに紐づくアプリケーションがありません。
      <br />
      アプリケーションを追加できるのは管理者のみです。
    </div>
  )
}
