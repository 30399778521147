import React, { FC, useMemo, useRef, useState } from 'react'
import { handleClickApp } from 'scripts/handleClickApp'
import { AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { ExtensionGuideModalMobile } from 'presentation/pages/apps/AppExists/Common/ExtensionGuideModalMobile'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { cookies } from 'scripts/cookies'
import { getIdentities } from 'api/getIdentities'
import { DecryptedIdentityType, IdentityType } from 'types/Identity'
import { RegisterIdentityDialog } from '../../../Common/RegisterIdentityDialog'
import { EditIdentityDialog } from '../../../Common/EditIdentityDialog'
import { DialogToSelectIdentityToUpdate } from '../../../Common/DialogToSelectIdentityToUpdate'
import { DialogToSelectIdentityToDelete } from '../../../Common/DialogToSelectIdentityToDelete'
import { getCanUserWriteIdentity } from 'scripts/getCanUserWriteIdentity'
import { Box, IconButton, Tooltip } from '@mui/material'
import { InsertLink, MoreHoriz } from '@mui/icons-material'
import { AppMenuForAppsPage } from '../../../Common/AppMenuForAppsPage'
import { getDecryptedIdentity } from 'api/getDecryptedIdentity'
import { ViewIdentityDialog } from '../../../Common/ViewIdentityDialog'
import { DialogToSelectIdentityToView } from '../../../Common/DialogToSelectIdentityToView'
import { DeleteIdentityDialog } from '../../../Common/DeleteIdentityDialog'
interface Props {
  app: AppWithSortedIndexWithIdentityExistsType
  isExtensionInstalled: boolean
  openInNewTabWhenAccessingAppInEnduserPortal: boolean | undefined
  apps: AppWithSortedIndexWithIdentityExistsType[]
  setApps: React.Dispatch<
    React.SetStateAction<AppWithSortedIndexWithIdentityExistsType[] | undefined>
  >
  appIndex: number
  showMenu: boolean
}
export const DefaultSmall: FC<Props> = ({
  app,
  isExtensionInstalled,
  openInNewTabWhenAccessingAppInEnduserPortal,
  apps,
  appIndex,
  setApps,
  showMenu,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()
  const [identity, setIdentity] = useState<IdentityType>()
  const [decryptedIdentity, setDecryptedIdentity] =
    useState<DecryptedIdentityType>()
  const [identities, setIdentities] = useState<IdentityType[]>([])
  const [isOpenRegisterIdentityDialog, setIsOpenRegisterIdentityDialog] =
    useState(false)
  const [isOpenEditIdentityDialog, setIsOpenEditIdentityDialog] =
    useState(false)
  const [isOpenDeleteIdentityDialog, setIsOpenDeleteIdentityDialog] =
    useState(false)
  const [isOpenViewIdentityDialog, setIsOpenViewIdentityDialog] =
    useState(false)
  const [
    isOpenDialogToSelectIdentityToUpdate,
    setIsOpenDialogToSelectIdentityToUpdate,
  ] = useState(false)
  const [
    isOpenDialogToSelectIdentityToView,
    setIsOpenDialogToSelectIdentityToView,
  ] = useState(false)
  const [
    isOpenDialogToSelectIdentityToDelete,
    setIsOpenDialogToSelectIdentityToDelete,
  ] = useState(false)
  const [isOpenGuideModal, setIsOpenGuideModal] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const needToAddIdentities = useMemo(() => {
    if (app.connection.conn_type === 'bookmark') {
      return false
    }
    const canUserUpdateIdentity = getCanUserWriteIdentity(app)
    return canUserUpdateIdentity && !app.identity_exists
  }, [app])

  const handleClick = async () => {
    // 以下の問題に対応するために追加
    // アプリに認証情報を追加した後、アプリにログインしてしまうので、以下の対応をした
    // ダイアログが開いている || メニューが表示されている　場合はクリックを無視する
    // https://github.com/OnetapInc/locked/issues/1522
    const disableClick =
      openMenu || isOpenRegisterIdentityDialog || isOpenEditIdentityDialog
    if (disableClick) return

    await handleClickApp(
      app,
      isExtensionInstalled,
      setIsOpenGuideModal,
      openAddIdentityDialog,
      openInNewTabWhenAccessingAppInEnduserPortal,
    )
  }

  const openAddIdentityDialog = () => {
    setIsOpenRegisterIdentityDialog(true)
  }

  const handleClickIdentityItemToUpdate = (identity: IdentityType) => {
    setIsOpenDialogToSelectIdentityToUpdate(false)
    setIsOpenEditIdentityDialog(true)
    setIdentity(identity)
  }

  const handleClickIdentityItemToDelete = (identity: IdentityType) => {
    setIsOpenDialogToSelectIdentityToDelete(false)
    setIsOpenDeleteIdentityDialog(true)
    setIdentity(identity)
  }

  const handleClickIdentityItemToView = (identity: IdentityType) => {
    getDecryptedIdentity({
      conn_id: app.connection.id,
      session_token: cookies.get('session_token'),
      identity_self_id: identity.id,
    }).then(({ data, status }) => {
      if (status !== 200) {
        openSnackbar(
          t(
            'ログイン情報の取得に失敗しました。しばらくしてから再度お試しください。',
          ),
        )
        return
      }

      setDecryptedIdentity(data.identity)
      setIsOpenDialogToSelectIdentityToView(false)
      setIsOpenViewIdentityDialog(true)
    })
  }

  const openDialogToSelectIdentityToUpdate = async () => {
    setIdentity(undefined)
    if (typeof app.id !== 'number') {
      openSnackbar(
        t(
          'ログイン情報の取得に失敗しました。しばらくしてから再度お試しください。',
        ),
      )
      return
    }

    const sessionToken = cookies.get('session_token')
    const { data, status } = await getIdentities({
      app_id: app.id,
      session_token: sessionToken,
    })
    if (status !== 200) {
      openSnackbar(
        t(
          'ログイン情報の取得に失敗しました。しばらくしてから再度お試しください。',
        ),
      )
      return
    }

    setIdentities(data.identities || [])
    setIsOpenDialogToSelectIdentityToUpdate(true)
  }

  const openDialogToSelectIdentityToDelete = async () => {
    setIdentity(undefined)
    if (typeof app.id !== 'number') {
      openSnackbar(
        t(
          'ログイン情報の取得に失敗しました。しばらくしてから再度お試しください。',
        ),
      )
      return
    }

    const sessionToken = cookies.get('session_token')
    const { data, status } = await getIdentities({
      app_id: app.id,
      session_token: sessionToken,
    })
    if (status !== 200) {
      openSnackbar(
        t(
          'ログイン情報の取得に失敗しました。しばらくしてから再度お試しください。',
        ),
      )
      return
    }

    setIdentities(data.identities || [])
    setIsOpenDialogToSelectIdentityToDelete(true)
  }

  const openDialogToSelectIdentityToView = async () => {
    setIdentity(undefined)
    if (typeof app.id !== 'number') {
      openSnackbar(
        t('認証情報の取得に失敗しました。しばらくしてから再度お試しください。'),
      )
      return
    }

    const sessionToken = cookies.get('session_token')
    const { data, status } = await getIdentities({
      app_id: app.id,
      session_token: sessionToken,
    })
    if (status !== 200) {
      openSnackbar(
        t('認証情報の取得に失敗しました。しばらくしてから再度お試しください。'),
      )
      return
    }

    setIdentities(data.identities || [])
    setIsOpenDialogToSelectIdentityToView(true)
  }

  const handleClickIconButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (needToAddIdentities) {
      openAddIdentityDialog()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const handleClickAddIdentity = (event: React.MouseEvent<HTMLLIElement>) => {
    // 以下の問題に対応するために追加
    // アプリに認証情報を追加した後、アプリにログインしてしまう問題があり、メニューをクリックしたときにも発生可能性があると思い、以下の対応をした
    // stopPropagation, preventDefaultを追加
    // https://github.com/OnetapInc/locked/issues/1522
    event.preventDefault()
    event.stopPropagation()

    openAddIdentityDialog()
    handleCloseMenu()
  }

  const handleClickUpdateIdentity = async (
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // 以下の問題に対応するために追加
    // アプリに認証情報を追加した後、アプリにログインしてしまう問題があり、メニューをクリックしたときにも発生可能性があると思い、以下の対応をした
    // stopPropagation, preventDefaultを追加
    // https://github.com/OnetapInc/locked/issues/1522
    event.preventDefault()
    event.stopPropagation()

    await openDialogToSelectIdentityToUpdate()
    handleCloseMenu()
  }

  const handleClickViewIdentity = async (
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // 以下の問題に対応するために追加
    // アプリに認証情報を追加した後、アプリにログインしてしまう問題があり、メニューをクリックしたときにも発生可能性があると思い、以下の対応をした
    // stopPropagation, preventDefaultを追加
    // https://github.com/OnetapInc/locked/issues/1522
    event.preventDefault()
    event.stopPropagation()

    await openDialogToSelectIdentityToView()
    handleCloseMenu()
  }

  const handleClickDeleteIdentity = async (
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // 以下の問題に対応するために追加
    // アプリに認証情報を追加した後、アプリにログインしてしまう問題があり、メニューをクリックしたときにも発生可能性があると思い、以下の対応をした
    // stopPropagation, preventDefaultを追加
    // https://github.com/OnetapInc/locked/issues/1522
    event.preventDefault()
    event.stopPropagation()

    await openDialogToSelectIdentityToDelete()
    handleCloseMenu()
  }

  return (
    <>
      <ExtensionGuideModalMobile
        closeExtensionGuideModal={() => setIsOpenGuideModal(false)}
        isOpenGuideModal={isOpenGuideModal}
      />
      <div
        data-test-app-index={appIndex}
        data-test-app-id={app.id}
        ref={ref}
        id={app.client_key}
        data-test={app.app_type}
        data-testid="app-item"
        data-test-app-connection-type={app.connection.conn_type}
        onClick={handleClick}
        style={{
          background: '#FFF',
          width: 136,
          height: 136,
          border: '1px solid #BDBDBD',
          boxSizing: 'border-box',
          borderRadius: '5px',
          cursor: 'pointer',
          opacity: needToAddIdentities ? 0.5 : 1,
        }}
        className="m-3 flex flex-col items-center"
      >
        {showMenu &&
          (getCanUserWriteIdentity(app) || app.allow_enduser_password_view) && (
            <div className="relative self-end">
              <div className="absolute" style={{ top: 0, right: 0 }}>
                <IconButton
                  data-testid="app-item-menu-button"
                  aria-label="open-menu"
                  onClick={handleClickIconButton}
                  sx={{ borderRadius: 0, width: 48, height: 48 }}
                >
                  <MoreHoriz />
                </IconButton>
              </div>
            </div>
          )}
        {app.connection.conn_type === 'bookmark' && (
          <Tooltip
            disableInteractive
            title={'このアプリはブックマークです'}
            placement="top"
          >
            <Box position="relative" alignSelf={'flex-start'}>
              <Box
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                height={48}
                width={48}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                position={'absolute'}
                top={0}
                left={0}
              >
                <InsertLink />
              </Box>
            </Box>
          </Tooltip>
        )}
        <div
          className="mt-5"
          style={{
            width: 60,
            height: 60,
          }}
        >
          <img src={app.logo_image} width={60} height={60}></img>
        </div>
        <div
          className="mx-3 mt-3"
          style={{
            textAlign: 'center',
            fontSize: '11px',
            lineHeight: '140.5%',
            color: '#333333',
          }}
        >
          {app.app_name}
        </div>
      </div>
      <RegisterIdentityDialog
        app={app}
        isOpen={isOpenRegisterIdentityDialog}
        setIsOpen={setIsOpenRegisterIdentityDialog}
        apps={apps}
        setApps={setApps}
      />
      {identity && (
        <EditIdentityDialog
          app={app}
          identity={identity}
          isOpen={isOpenEditIdentityDialog}
          setIsOpen={setIsOpenEditIdentityDialog}
        />
      )}
      {decryptedIdentity && (
        <ViewIdentityDialog
          app={app}
          identity={decryptedIdentity}
          isOpen={isOpenViewIdentityDialog}
          setIsOpen={setIsOpenViewIdentityDialog}
        />
      )}
      {identity && (
        <DeleteIdentityDialog
          app={app}
          identity={identity}
          isOpen={isOpenDeleteIdentityDialog}
          setIsOpen={setIsOpenDeleteIdentityDialog}
        />
      )}
      <DialogToSelectIdentityToUpdate
        handleClickIdentityItem={handleClickIdentityItemToUpdate}
        app={app}
        identities={identities}
        isOpen={isOpenDialogToSelectIdentityToUpdate}
        setIsOpen={setIsOpenDialogToSelectIdentityToUpdate}
      />
      <DialogToSelectIdentityToView
        handleClickIdentityItem={handleClickIdentityItemToView}
        app={app}
        identities={identities}
        isOpen={isOpenDialogToSelectIdentityToView}
        setIsOpen={setIsOpenDialogToSelectIdentityToView}
      />
      <DialogToSelectIdentityToDelete
        handleClickIdentityItem={handleClickIdentityItemToDelete}
        app={app}
        identities={identities}
        isOpen={isOpenDialogToSelectIdentityToDelete}
        setIsOpen={setIsOpenDialogToSelectIdentityToDelete}
      />
      <AppMenuForAppsPage
        app={app}
        anchorElement={anchorEl}
        open={openMenu}
        handleClick={handleClickMenu}
        handleClose={handleCloseMenu}
        handleClickAddIdentity={handleClickAddIdentity}
        handleClickUpdateIdentity={handleClickUpdateIdentity}
        handleClickViewIdentity={handleClickViewIdentity}
        handleClickDeleteIdentity={handleClickDeleteIdentity}
      />
    </>
  )
}
