import React from 'react'
import LockedLogo from 'images/lockedlogo.png'
import axios from 'axios'
import { LogoutResponse } from 'types/ApiResponse'
import { cookies } from 'scripts/cookies'
import { fullUrl } from 'scripts/fullUrl'
import { navigate } from 'gatsby'
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { LARGE_APP_LIST_MAX_WIDTH } from 'presentation/pages/apps/AppExists/Large/AppList'

type Props = {
  handleChangeAppIconSize?: (
    size: 'large' | 'medium' | 'small',
  ) => Promise<void>
  handleToggleShowAppMenu?: () => void
  handleChangeCanSortApps?: (canSortApps: boolean) => void
  canSortApps: boolean
  canUseAccessRequest: boolean
}

export const LogoPart: React.FC<Props> = ({
  handleChangeAppIconSize,
  handleToggleShowAppMenu,
  handleChangeCanSortApps,
  canSortApps,
  canUseAccessRequest,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openAppIconSettingCollapse, setOpenAppIconSettingCollapse] =
    React.useState(false)
  const handleClickAppIconSettingCollapse = () => {
    setOpenAppIconSettingCollapse(!openAppIconSettingCollapse)
  }
  const [openAccessRequestCollapse, setOpenAccessRequestCollapse] =
    React.useState(false)
  const handleClickAccessRequestCollapse = () => {
    setOpenAccessRequestCollapse(!openAccessRequestCollapse)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickLogout = () => {
    const sessionToken = cookies.get('session_token')
    axios
      .get<LogoutResponse>(`${fullUrl()}/logout`, {
        params: {
          session_token: sessionToken,
        },
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        cookies.removeSessionToken()
        navigate('/login')
      })
  }

  const handleClickDeviceApprovalRequest = () => {
    navigate('/deviceApprovalRequest')
  }

  const LogoPartStyle: React.CSSProperties = {
    background: '#FFFFFF',
    boxShadow: '0px 2px 6px rgba(10, 6, 17, 0.15)',
    height: 52,
  }

  return (
    <div className="flex items-center justify-center" style={LogoPartStyle}>
      <div
        style={{
          width: LARGE_APP_LIST_MAX_WIDTH,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <div />
        <img
          src={LockedLogo}
          style={{
            width: 96,
            display: 'block',
          }}
        />
        <Button
          data-testid="toggle-show-setting-menu-button"
          variant="outlined"
          color="inherit"
          sx={{ justifySelf: 'end', marginRight: '12px' }}
          onClick={handleClick}
        >
          設定
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {handleToggleShowAppMenu && (
            <MenuItem
              data-testid="toggle-show-app-item-menu-button"
              onClick={() => {
                handleToggleShowAppMenu()
                handleClose()
              }}
            >
              ログイン情報の設定
            </MenuItem>
          )}
          {handleChangeAppIconSize && (
            <>
              <MenuItem onClick={handleClickAppIconSettingCollapse}>
                アプリアイコンのサイズ
                {openAppIconSettingCollapse ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={openAppIconSettingCollapse}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleChangeAppIconSize('large')}
                  >
                    大きい
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleChangeAppIconSize('medium')}
                  >
                    普通
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleChangeAppIconSize('small')}
                  >
                    小さい
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}
          {handleChangeCanSortApps && (
            <MenuItem
              data-testid="toggle-can-sort-apps-menu-button"
              onClick={() => handleChangeCanSortApps(!canSortApps)}
            >
              並べ替え{canSortApps ? '終了' : '開始'}
            </MenuItem>
          )}
          <MenuItem
            data-testid="device-approval-request-menu-button"
            onClick={() => handleClickDeviceApprovalRequest()}
          >
            端末をリクエスト
          </MenuItem>
          {canUseAccessRequest && (
            <>
              <MenuItem onClick={handleClickAccessRequestCollapse}>
                権限の申請・承認
                {openAccessRequestCollapse ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={openAccessRequestCollapse}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => navigate('/accessRequest')}
                  >
                    申請
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => navigate('/accessRequestApprove')}
                  >
                    承認
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}
          <MenuItem data-testid="logout-button" onClick={handleClickLogout}>
            ログアウト
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
