import React from 'react'
import { AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { MobileFormBasedAuthSmall } from './MobileFormBasedAuthSmall'
import { executableFormBasedAuthOnMobile } from 'scripts/executableFormBasedAuthOnMobile'
import { DefaultSmall } from './DefaultSmall'

type Props = {
  app: AppWithSortedIndexWithIdentityExistsType
  isExtensionInstalled: boolean
  openInNewTabWhenAccessingAppInEnduserPortal: boolean | undefined
  setApps: React.Dispatch<
    React.SetStateAction<AppWithSortedIndexWithIdentityExistsType[] | undefined>
  >
  apps: AppWithSortedIndexWithIdentityExistsType[]
  appIndex: number
  showMenu: boolean
}

export const AppSmall: React.FC<Props> = ({
  app,
  isExtensionInstalled,
  openInNewTabWhenAccessingAppInEnduserPortal,
  setApps,
  apps,
  appIndex,
  showMenu,
}) => {
  if (executableFormBasedAuthOnMobile(app)) {
    return <MobileFormBasedAuthSmall app={app} />
  } else {
    return (
      <DefaultSmall
        app={app}
        isExtensionInstalled={isExtensionInstalled}
        openInNewTabWhenAccessingAppInEnduserPortal={
          openInNewTabWhenAccessingAppInEnduserPortal
        }
        apps={apps}
        setApps={setApps}
        appIndex={appIndex}
        showMenu={showMenu}
      />
    )
  }
}
