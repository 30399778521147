import React from 'react'
import { UserInfoPart } from 'presentation/pages/apps/Common/Header/Large/UserInfoPart/userInfo'
import { LogoPart } from 'presentation/pages/apps/Common/Header/Large/LogoPart'

type Props = {
  teamName: string | undefined
  userName: string | undefined
  canUseAccessRequest: boolean
  handleChangeAppIconSize?: (
    size: 'large' | 'medium' | 'small',
  ) => Promise<void>
  handleToggleShowAppMenu?: () => void
  handleChangeCanSortApps?: (canSortApps: boolean) => void
  canSortApps: boolean
}

export const HeaderLarge: React.FC<Props> = ({
  teamName,
  userName,
  handleChangeAppIconSize,
  handleToggleShowAppMenu,
  handleChangeCanSortApps,
  canSortApps,
  canUseAccessRequest,
}) => {
  return (
    <div className="fixed w-full" style={{ zIndex: 1 }}>
      <UserInfoPart teamName={teamName} userName={userName}></UserInfoPart>
      {/* <div className="" style={UserInfoPartStyle}></div> */}
      <LogoPart
        handleChangeAppIconSize={handleChangeAppIconSize}
        handleToggleShowAppMenu={handleToggleShowAppMenu}
        canSortApps={canSortApps}
        handleChangeCanSortApps={handleChangeCanSortApps}
        canUseAccessRequest={canUseAccessRequest}
      ></LogoPart>
    </div>
  )
}
