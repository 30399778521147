import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  deviceId?: string
}

export const DeviceIdShower: React.FC<Props> = ({ deviceId }) => {
  const { t } = useTranslation()
  return (
    <div
      className="flex items-center justify-center"
      style={{
        marginTop: '27px',
        background: '#F2F2F2',
        borderRadius: '3px',
        padding: '2px 6px 2px 4px',
      }}
    >
      <span
        style={{
          fontWeight: 500,
          fontSize: '11px',
          lineHeight: '156%',
          letterSpacing: '0.04em',
          color: '#4F4F4F',
          marginRight: '4px',
        }}
      >
        {t('端末ID')}
      </span>
      <span
        style={{
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '156%',
          letterSpacing: '0.04em',
          color: '#2F80ED',
        }}
      >
        {deviceId}
      </span>
    </div>
  )
}
