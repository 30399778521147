import { postEnduserTotpTwoFactorAuthenticationResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const postEnduserTotpTwoFactorAuthentication = async (
  sessionToken: string,
  otpSecret: string,
  otpAttempt: string,
) => {
  const res =
    await axiosClient.post<postEnduserTotpTwoFactorAuthenticationResponse>(
      `${fullUrl('api')}/enduser/totp_two_factor_authentication`,
      {
        session_token: sessionToken,
        otp_secret: otpSecret,
        otp_attempt: otpAttempt,
      },
    )
  return res
}
