import { getEnduserTotpTwoFactorAuthenticationResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getEnduserTotpTwoFactorAuthentication = async (
  sessionToken: string,
) => {
  const res =
    await axiosClient.get<getEnduserTotpTwoFactorAuthenticationResponse>(
      `${fullUrl(
        'api',
      )}/enduser/totp_two_factor_authentication?session_token=${sessionToken}`,
    )
  return res
}
