import { GetIdentityExistsForEachAppsResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { axiosClient } from 'scripts/api'
import { cookies } from 'scripts/cookies'

export const getIdentityExistsForEachApps = async () => {
  const sessionToken = cookies.get('session_token')
  const res = await axiosClient.get<GetIdentityExistsForEachAppsResponse>(
    `${fullUrl()}/apps/each_has_user_identity`,
    {
      params: {
        session_token: sessionToken,
      },
    },
  )
  return res
}
