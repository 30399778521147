import React, { FC, useState } from 'react'
import { AppType } from 'types/App'
import { ExtensionGuideModalMobile } from 'presentation/pages/apps/AppExists/Common/ExtensionGuideModalMobile'
interface Props {
  app: AppType
}

export const MobileFormBasedAuthSmall: FC<Props> = ({ app }) => {
  const [isOpenGuideModal, setIsOpenGuideModal] = useState(false)
  return (
    <>
      <ExtensionGuideModalMobile
        closeExtensionGuideModal={() => setIsOpenGuideModal(false)}
        isOpenGuideModal={isOpenGuideModal}
      />
      <div
        id={app.client_key}
        data-test={app.app_type}
        data-test-app-connection-type={app.connection.conn_type}
        onClick={() => setIsOpenGuideModal(true)}
        style={{
          background: '#FFF',
          width: 136,
          height: 136,
          border: '1px solid #BDBDBD',
          boxSizing: 'border-box',
          borderRadius: '5px',
        }}
        className="m-3 flex flex-col items-center relative"
      >
        <div
          className="absolute top-0 py-5 px-5"
          style={{
            zIndex: 1,
            background: '#A8A8A8',
            opacity: 0.8,
            borderRadius: '4px',
            width: '100%',
            height: '100%',
          }}
        >
          <span
            style={{
              display: 'block',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '140.5%',
              textAlign: 'center',
              letterSpacing: '0.03em',
              color: '#FFF',
            }}
          >
            PCもしくはスマートフォンアプリからのみご利用いただけます
          </span>
        </div>
        <div
          className="mt-5"
          style={{
            width: 60,
            height: 60,
          }}
        >
          <img src={app.logo_image} width={60} height={60}></img>
        </div>
        <div
          className="mx-3 mt-3"
          style={{
            textAlign: 'center',
            fontSize: '11px',
            lineHeight: '140.5%',
            color: '#333333',
          }}
        >
          {app.app_name}
        </div>
      </div>
    </>
  )
}
