import React from 'react'
import { DeviceIdShower } from 'presentation/components/AuthenticationLayout/DeviceIdShower'

interface Props {
  deviceId: string | undefined
}

export const FooterSmall: React.FC<Props> = ({ deviceId }) => {
  return (
    <div className="pb-1 mt-8 absolute bottom-0" style={{ width: '100%' }}>
      <div className="flex justify-center mb-4">
        <DeviceIdShower deviceId={deviceId}></DeviceIdShower>
      </div>
      <div style={{ height: 20 }} className="justify-center flex ">
        <span
          className="align-text-bottom"
          style={{
            color: '#828282',
            fontSize: '10px',
            lineHeight: '156.5%',
          }}
        >
          Onetap, Inc &copy {new Date().getFullYear()}
        </span>
      </div>
    </div>
  )
}
