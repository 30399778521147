import { GetEnduserDeviceResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { axiosClient } from 'scripts/api'
import { cookies } from 'scripts/cookies'

export const getEnduserDevice = async () => {
  const sessionToken = cookies.get('session_token')
  const res = await axiosClient.get<GetEnduserDeviceResponse>(
    `${fullUrl('api')}/enduser_device_sessions/device`,
    {
      params: {
        session_token: sessionToken,
      },
    },
  )
  return res
}
