import { patchEnduserPasswordUpdateBySelfResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const patchEnduserPasswordUpdateBySelf = async (
  sessionToken: string,
  newPassword: string,
) => {
  const res = await axiosClient.patch<patchEnduserPasswordUpdateBySelfResponse>(
    `${fullUrl('api')}/endusers/update_password_by_self`,
    {
      session_token: sessionToken,
      new_password: newPassword,
    },
  )
  return res
}
