import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { cookies } from 'scripts/cookies'
import { getApps } from 'api/getApps'
import { Loading } from 'presentation/components/Loading'
import { navigate, PageProps } from 'gatsby'
import { ToAppsState } from 'types/NavigateState'
import { NoApp } from './NoApp'
import { AppExists } from './AppExists'
import { Helmet } from 'react-helmet'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { StringParam, useQueryParam } from 'use-query-params'
import { deleteQueryParams } from 'scripts/deleteQueryParams'
import { AppType, AppWithSortedIndexWithIdentityExistsType } from 'types/App'
import { SendLockedSessionTokenToExtension } from 'scripts/sendMessageToExtension'
import { isOpenInLockedApp } from 'scripts/isOpenInLockedApp'
import { getIdentityExistsForEachApps } from 'api/getIdentityExistsForEachApps'
import { sleep } from 'scripts/sleep'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'
import { SendUrlMatchFormBasedAuthParams } from 'scripts/sendMessageToExtension/sendUrlMatchFormBasedAuthParams'
import { getEnduser } from 'api/getEnduser'
import { getEnduserDevice } from 'api/getEnduserDevice'
import { convertAppsToExtensionApps } from 'scripts/convertAppsToExtensionApps'
import { LARGE_APP_LIST_MAX_WIDTH } from './AppExists/Large/AppList'
import { APP_ICON_SIZE } from 'e2e/constants/appIconSize'
import { patchEnduserAppIconSizeInThePortal } from 'api/patchEnduserAppIconSizeInThePortal'

export type AppIconObj = {
  itemsPerRow: number
  logoSize: number
  fontAreaWidth: number
  logoMarginTop: number
  bookmarkButtonWidth: number
  bookmarkButtonHeight: number
  menuButtonWidth: number
  menuButtonHeight: number
  fontSize: string
  appHeight: number
}

const getAppIconObj = (size: keyof typeof APP_ICON_SIZE): AppIconObj => {
  const mediumResult = {
    itemsPerRow: 6,
    logoSize: 100,
    fontAreaWidth: 140,
    logoMarginTop: 5,
    bookmarkButtonWidth: 48,
    bookmarkButtonHeight: 48,
    menuButtonWidth: 48,
    menuButtonHeight: 48,
    fontSize: 'text-xs',
    appHeight: 158,
  }
  switch (size) {
    case APP_ICON_SIZE.large:
      return {
        itemsPerRow: 4,
        logoSize: 140,
        fontAreaWidth: 220,
        logoMarginTop: 30,
        bookmarkButtonWidth: 48,
        bookmarkButtonHeight: 48,
        menuButtonWidth: 48,
        menuButtonHeight: 48,
        fontSize: 'text-sm',
        appHeight: 242,
      }
    case APP_ICON_SIZE.medium:
      return mediumResult
    case APP_ICON_SIZE.small:
      return {
        itemsPerRow: 8,
        logoSize: 70,
        fontAreaWidth: 100,
        logoMarginTop: 0,
        bookmarkButtonWidth: 33,
        bookmarkButtonHeight: 29,
        menuButtonWidth: 33,
        menuButtonHeight: 29,
        fontSize: 'text-xs',
        appHeight: 116,
      }
    default:
      return mediumResult
  }
}

export const Apps = (props: PageProps) => {
  const navigateState: ToAppsState = props.location.state as ToAppsState
  const [loading, setLoading] = useState<boolean>(true)
  const [apps, setApps] = useState<AppWithSortedIndexWithIdentityExistsType[]>()
  const [teamName, setTeamName] = useState<string>()
  const [canUseAccessRequest, setCanUseAccessRequest] = useState<boolean>(false)
  const [
    openInNewTabWhenAccessingAppInEnduserPortal,
    setOpenInNewTabWhenAccessingAppInEnduserPortal,
  ] = useState<boolean>()
  const [userName, setUserName] = useState<string>()
  const [deviceId, setDeviceId] = useState<string>()
  const [openSnackbar] = useSnackbar()
  const [windowWidth, setWindowWidth] = useState<number>()
  const [snackbarQueryParam] = useQueryParam('snackbarText', StringParam)
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)
  const [chromeExtensionInstalled, setChromeExtensionInstalled] =
    useState<boolean>(false)
  const [appIconObj, setAppIconSizeObj] = useState<AppIconObj>()
  const [enduserSelfId, setEnduserSelfId] = useState<string>()
  const [canSortApps, setCanSortApps] = useState(false)

  useEffect(() => {
    const sessionToken = cookies.get('session_token')
    ;(async () => {
      const extensionParams = { lockedSessionToken: sessionToken }
      for await (const _ of Array(10).keys()) {
        const result = await new SendLockedSessionTokenToExtension(
          extensionParams,
        ).exec()
        if (result.success) {
          if (chromeExtensionInstalled !== true) {
            setChromeExtensionInstalled(true)
          }
          return
        } else {
          // 何もしない
        }
        await sleep(1000)
      }
      if (chromeExtensionInstalled !== false) {
        setChromeExtensionInstalled(false)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      // HACK: useEffectはwindow見つからないことはないはず。refactor
      if (window) {
        setWindowWidth(window.innerWidth)
      }
      const subdomain = getSubdomain(window.location.host)
      const sessionToken = cookies.get('session_token')
      if (!sessionToken || subdomain === '') {
        navigate('/login', { replace: true })
        return
      }

      const [
        appsResponse,
        enduserResponse,
        enduserDeviceResponse,
        identityExistsForEachAppsResponse,
      ] = await Promise.all([
        getApps({
          sessionToken,
        }),
        getEnduser(),
        getEnduserDevice(),
        getIdentityExistsForEachApps(),
      ])
      if (
        appsResponse.status !== 200 ||
        enduserResponse.status !== 200 ||
        enduserDeviceResponse.status !== 200 ||
        identityExistsForEachAppsResponse.status !== 200
      ) {
        navigate('/login', { replace: true })
        return
      }
      setLoading(false)

      const mergedApps = appsResponse.data.apps?.map(
        (app): AppWithSortedIndexWithIdentityExistsType => {
          const identityExists =
            identityExistsForEachAppsResponse.data.apps.find(
              (identityExists) => {
                return (
                  typeof identityExists.id === 'number' &&
                  identityExists.id === app.id
                )
              },
            )
          return {
            ...app,
            identity_exists: identityExists?.identity_exists ?? false,
          }
        },
      )

      const filteredApps = isOpenInLockedApp
        ? mergedApps?.filter((a) => a.connection.conn_type !== 'saml')
        : mergedApps
      setTeamName(enduserResponse.data.enduser.team_name)
      setCanUseAccessRequest(
        enduserResponse.data.enduser.can_use_access_request,
      )
      setOpenInNewTabWhenAccessingAppInEnduserPortal(
        enduserResponse.data.enduser
          .open_in_new_tab_when_accessing_app_in_enduser_portal,
      )
      const { first_name, middle_name, last_name } =
        enduserResponse.data.enduser
      const userName = `${last_name} ${middle_name ?? ''} ${first_name}`
      setUserName(userName)
      setAppIconSizeObj(
        getAppIconObj(
          enduserResponse.data.enduser.app_icon_size_in_the_portal ??
            APP_ICON_SIZE.medium,
        ),
      )
      setEnduserSelfId(enduserResponse.data.enduser.id)
      setDeviceId(enduserDeviceResponse.data.device.id)
      setApps(filteredApps)
      const extensionApps = convertAppsToExtensionApps(appsResponse.data.apps)
      await new SendUrlMatchFormBasedAuthParams({
        apps: extensionApps,
      }).exec()

      // ログインできたら、subdomainを記録
      if (subdomain) {
        cookies.setSessionTokenToCookie(sessionToken, 123)
        cookies.setAuthSubdomainToCookie(subdomain)
      }
      if (navigateState?.snackbarText) {
        openSnackbar(
          navigateState.snackbarText,
          navigateState?.snackbarAttribute ?? 'hasNotIdentitySnackbar',
        )
      } else if (snackbarQueryParam) {
        openSnackbar(snackbarQueryParam, 'hasNotIdentitySnackbar')
        deleteQueryParams(['snackbarText'])
      }
    })()
  }, [])

  // query_paramsまたはnavigate_stateでapp_client_keyが渡ってきたらそのアプリをclick
  useEffect(() => {
    const appClientKey = appClientKeyQueryParam || navigateState?.appClientKey
    if (appClientKey) {
      autoAppAccess(apps, appClientKey)
    }
  }, [apps, appClientKeyQueryParam, navigateState])

  const autoAppAccess = (
    apps: AppType[] | undefined,
    appClientKey: string | null | undefined,
  ) => {
    if (Array.isArray(apps) && apps.length > 0 && appClientKey) {
      const found = apps.find((app) => {
        return app.client_key === appClientKey
      })

      if (found) {
        const el = document.getElementById(found.client_key)
        el?.click()
        deleteQueryParams(['app_client_key'])
      }
    }
  }

  const handleChangeAppIconSize = async (
    size: 'large' | 'medium' | 'small',
  ) => {
    if (typeof enduserSelfId !== 'string') {
      return
    }

    const { status } = await patchEnduserAppIconSizeInThePortal({
      enduser_self_id: enduserSelfId,
      app_icon_size_in_the_portal: size,
    })
    if (status !== 200) {
      openSnackbar('アプリアイコンサイズの変更に失敗しました。')
      return
    }

    const appIconObj = getAppIconObj(size)
    setAppIconSizeObj(appIconObj)
    openSnackbar('アプリアイコンサイズを変更しました。')
  }

  const handleChangeCanSortApps = (canSortApps: boolean) => {
    setCanSortApps(canSortApps)
  }

  if (
    loading === false &&
    typeof windowWidth === 'number' &&
    Array.isArray(apps) &&
    typeof appIconObj !== 'undefined'
  ) {
    return apps.length > 0 ? (
      <>
        <Helmet>
          <title data-test="appsPage">LOCKED マイページ</title>
        </Helmet>
        <DndProvider backend={HTML5Backend}>
          <AppExists
            apps={apps}
            teamName={teamName}
            canUseAccessRequest={canUseAccessRequest}
            openInNewTabWhenAccessingAppInEnduserPortal={
              openInNewTabWhenAccessingAppInEnduserPortal
            }
            userName={userName}
            deviceId={deviceId}
            windowWidth={windowWidth}
            chromeExtensionInstalled={chromeExtensionInstalled}
            setApps={setApps}
            appIconObj={appIconObj}
            handleChangeAppIconSize={handleChangeAppIconSize}
            canSortApps={canSortApps}
            handleChangeCanSortApps={handleChangeCanSortApps}
          />
        </DndProvider>
      </>
    ) : (
      <>
        <Helmet>
          <title data-test="appsPage">LOCKED マイページ</title>
        </Helmet>
        <NoApp
          teamName={teamName}
          canUseAccessRequest={canUseAccessRequest}
          userName={userName}
          deviceId={deviceId}
          windowWidth={windowWidth}
        />
      </>
    )
  }

  return (
    <>
      <main
        className="h-full w-full flex justify-center items-center"
        style={{ background: '#E5E5E5' }}
      >
        <Loading />
      </main>
    </>
  )
}
